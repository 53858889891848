import React,{useState,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { BrowserRouter as  Router,Routes,Route} from "react-router-dom";
import "./component/style/style.css";
import MainPage from "./component/MainPage/mainPage";
const App = () =>  {
  return (
    <Router>
      <Routes>
        <Route path="/*"   element={<MainPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
